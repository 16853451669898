import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import {environment} from "../../../environments/environment";
import { Customer } from '../../shared-objects'

@Component({
  selector: 'tr-konzept-subheader',
  templateUrl: './konzept-subheader.component.html',
  styleUrls: ['./konzept-subheader.component.scss'],
  host: { class: 'w-full' }
})
export class KonzeptSubheaderComponent  {
  environment = environment;

  @Input()
  customer?: Customer;
  @Input()
  hideButtons: boolean;

  constructor(readonly router: Router) { }
}
