<h2 mat-dialog-title class="mb-0">Hinweis:</h2>
<mat-dialog-content>
  Es liegt noch keine unterschriebene separate Maklervollmacht vor oder die Vollmacht des Kunden ist nicht aktuell (ab Version 1.2).<br/>
  <b>Die Einreichung der Bestandsübertragung ist bereits möglich, jedoch wird sie erst nach Prüfung und Hinterlegung der Vollmacht endgültig versendet.</b><br/>
  Bei Rückfragen wende dich bitte an die <a class="text-taures-blau" target="_blank" href="https://kuemmerkasten.k8s.taures.de/form/70">Zentrale Antragsabteilung</a>.
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-stroked-button color="link" (click)="close()">Weiter ohne aktuelle Vollmacht</button>
  <button mat-flat-button color="primary" (click)="redirectToKonzept()">Jetzt aktuelle Vollmacht generieren</button>
</mat-dialog-actions>
