import {
    AfterViewChecked,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Inject,
    OnInit,
    QueryList,
    ViewChildren,
    ViewEncapsulation
} from '@angular/core';
import {ExternalContractsService} from '../../services/external-contracts.service';
import {Customer, ExternalContractData, INSTRUCTION_TRANSFER_TO_QUALITYPOOL} from '../../shared-objects';
import {ActivatedRoute} from '@angular/router';
import {BrokerMandateOverlayComponent} from '../broker-mandate-overlay/broker-mandate-overlay.component';
import {CancelOperationOverlayComponent} from '../cancel-operation-overlay/cancel-operation-overlay.component';
import {DetailComponent} from '../external-contracts-detail/detail.component';
import {DOCUMENT} from '@angular/common';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {
    DIALOG_STATE,
    PreCheckOverlayComponent,
    PROCESS_ERRORS
} from '../pre-check-contract-transmission-overlay/pre-check-overlay.component';
import {ErrorMessageOverlayComponent} from '../error-message-overlay/error-message-overlay.component';
import {PersonsService} from '../../services/persons.service';
import {catchError} from 'rxjs/operators';
import {BrokerContractOverlayComponent} from '../broker-contract-overlay/broker-contract-overlay.component';
import {ProvisionChangeOverlayComponent} from '../provision-change-overlay/provision-change-overlay.component';
import {QueueWarningOverlayComponent} from '../queue-warning-overlay/queue-warning-overlay.component';
import {KeycloakTokenService, SnackbarService} from '@taures/angular-commons';
import {EMPTY, forkJoin, Observable} from 'rxjs'
import {NotificationDialogComponent} from '../notification-dialog/notification-dialog.component'

export function handleProcessError(dialog: MatDialog, err: any): Observable<any> {
    const message = PROCESS_ERRORS[err.error?.messageKey ?? 'unknown'] ?? PROCESS_ERRORS['unknown'];
    const error = err.error?.messageKey === 'documentation';
    const details = err.error?.details ?? []
    dialog.open(NotificationDialogComponent, {
        width: '340px',
        panelClass: error ? 'error-dialog' : 'notification-dialog',
        data: { message, error, details}
    });
    return EMPTY;
}

@Component({
    selector: 'tr-insurer-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
    encapsulation: ViewEncapsulation.None,
    host: { class: 'flex flex-column overflow-y-auto h-full' }
})
export class ListComponent implements OnInit, AfterViewChecked {

    constructor(@Inject(DOCUMENT) private document: Document,
                private route: ActivatedRoute,
                public externalContractsService: ExternalContractsService,
                private personsService: PersonsService,
                private dialog: MatDialog,
                private snackBarService: SnackbarService,
                private keycloakTokenService: KeycloakTokenService,
                private changeDetection: ChangeDetectorRef) {
        this.route.params.subscribe(params => {
            this.personManagementId = params['personManagementId'];
            this.crmContractId = Number(params['crmContractId']);
            if (isNaN(this.crmContractId)) {
                this.crmContractId = -1;
            } else {
                this.visitedFromCrm = true;
            }
        });
    }

    personManagementId: string;
    crmContractId: number;
    showSpinner = false;

    @ViewChildren(DetailComponent) contractTiles !: QueryList<DetailComponent>;

    public savingActivated = false;
    public changesOnTiles = false;
    public eachTileHasChosenTariff = false;
    public processingActivated = false;
    public eachTileHasChosenInstruction = false;
    public visitedFromCrm = false;
    public customer: Customer;
    public hasBrokerMandate = false;

    ngOnInit() {
        this.externalContractsService.loadInsurerList().subscribe();
        this.externalContractsService.loadDivisionsList().subscribe();
        this.externalContractsService.loadContractData(this.personManagementId, this.crmContractId).subscribe();
        this.externalContractsService.loadBrokerContractExistence(this.personManagementId).subscribe();
        this.externalContractsService.loadUserIsAuthorized34d().subscribe();
        this.externalContractsService.loadUserIsAuthorizedToProcessContracts().subscribe();

        forkJoin([this.personsService.loadCustomer(this.personManagementId),
            this.externalContractsService.loadBrokerMandate(this.personManagementId)])
            .subscribe(([customer]) => {
                this.customer = customer;
                this.personManagementId = customer?.personManagementId;
                this.hasBrokerMandate = this.externalContractsService.hasBrokerMandate();
                if (!this.hasBrokerMandate) {
                    this.dialog.open(BrokerMandateOverlayComponent,
                        {
                            width: '600px',
                            data: {customerId: this.customer.id},
                            autoFocus: false
                        }
                    );
                }
            })
    }

    /**
     * checks every few seconds for changes on view and view children
     */
    ngAfterViewChecked() {
        this.checkEachTileHasChosenTariff();
        this.checkEachTileHasChosenInstruction();
        this.checkSavingActivated();
        this.checkProcessingActivated();
        this.changeDetection.detectChanges();
    }

    cancel(): void {
        if (this.changesOnTiles) {
            const dialogRef = this.dialog.open(CancelOperationOverlayComponent,
                {
                    panelClass: 'error-dialog',
                    data: {
                        visitedFromCrm: this.visitedFromCrm,
                        personManagementId: this.personManagementId
                    },
                    autoFocus: false
                }
            );
            dialogRef.disableClose = true;
            dialogRef.componentInstance.discardChanges.subscribe(() => {
                dialogRef.close();
                window.close();
            });
        } else {
            if (this.visitedFromCrm) {
                window.close();
            } else {
                this.document.location.href = this.externalContractsService.getKonzeptUrl(this.personManagementId);
            }
        }
    }

    async save(): Promise<void> {
        const contracts: ExternalContractData[] = this.contractTiles
          .filter(tile => tile.formGroup.dirty)
          .map(tile => tile.getUpdatedExternalContract());

        if (contracts.length === 0) return

        await this.externalContractsService.saveContracts(contracts)
          .pipe(catchError(err =>
            ExternalContractsService.handleError(err, 'Die Daten konnten nicht gespeichert werden!')))
          .toPromise()
          .catch(error => {
              this.showErrorMessage(error)
              throw error
          })

        this.resetChangesOnTiles();
        this.snackBarService.queueToastMessage({
            title: 'Bestätigung',
            message: 'Änderungen gespeichert',
            duration: 6000,
            notificationType: 'confirmation'
        })

    }

    private checkSavingActivated(): void {
        this.changesOnTiles = false;
        this.contractTiles.forEach(tile => {
            if (tile.hasChanges) {
                this.changesOnTiles = true;
            }
        });

        this.savingActivated = this.changesOnTiles && this.eachTileHasChosenTariff;
    }

    private checkProcessingActivated(): void {
        this.processingActivated = this.externalContractsService.isUser34dRegistered()
            && this.externalContractsService.isUserAuthorizedToProcessContracts()
            && this.eachTileHasChosenInstruction
            && this.eachTileHasChosenTariff;
    }

    private showErrorMessage(err): void {
        const dialogRef = this.dialog.open(ErrorMessageOverlayComponent,
            {
                width: '340px',
                data: {
                    message: err
                }
            });
        dialogRef.disableClose = true;
    }

    async process(): Promise<void> {
        this.showSpinner = true;
        try {
            await this.save()

            if (!await this.externalContractsService.verifyContracts(this.personManagementId)
              .pipe(catchError(err => handleProcessError(this.dialog, err)))
              .toPromise()) {
                this.showSpinner = false;
                return
            }

            this.showSpinner = false;
            const dialogRef = this.dialog.open(PreCheckOverlayComponent,
              {
                  width: '800px',
                  data: {
                      personManagementId: this.personManagementId,
                      contractTiles: this.contractTiles,
                      customerMailAddress: this.customer.email,
                      consultantMailAddress: this.keycloakTokenService.email,
                      hasBrokerMandate: this.hasBrokerMandate
                  },
                  autoFocus: false
              }
            );

            dialogRef.disableClose = true;
            // cascade open overlays (PreCheck -> ProvisionChange -> possible missing Broker Contracts)
            dialogRef.componentInstance.transmit.subscribe((s: DIALOG_STATE) => {
                if (s === DIALOG_STATE.FINISHED) {
                    this.showSpinner = false;
                } else if (s === DIALOG_STATE.LOADING) {
                    this.showSpinner = true
                } else if (s === DIALOG_STATE.COMPLETED) {
                    this.showSpinner = false;
                    let anyContractShouldBeTransferred = false;
                    this.contractTiles.forEach(tile => {
                        if (tile.getSelectedInstruction() !== undefined
                            && tile.getSelectedInstruction().id === INSTRUCTION_TRANSFER_TO_QUALITYPOOL) {
                            anyContractShouldBeTransferred = true;
                        }
                    });
                    if (anyContractShouldBeTransferred && this.hasBrokerMandate) {
                        this.openProvisionChangeOverlay().componentInstance.transmit.subscribe(() => this.openBrokerContractOverlay());
                    } else if (anyContractShouldBeTransferred && !this.hasBrokerMandate) {
                        this.openQueueWarningOverlay().componentInstance.transmit.subscribe(
                            () => this.openProvisionChangeOverlay().componentInstance.transmit
                                .subscribe(() => this.openBrokerContractOverlay()));
                    } else {
                        this.redirectToKonzept();
                    }
                    return true;
                }
              }
            );
        } finally {
            this.showSpinner = false;
        }
    }

    private openQueueWarningOverlay(): MatDialogRef<QueueWarningOverlayComponent> {
        const dialogRef = this.dialog.open(QueueWarningOverlayComponent,
            {
                width: '660px',
                autoFocus: false
            }
        );
        dialogRef.disableClose = true;
        return dialogRef;
    }

    private resetChangesOnTiles(): void {
        this.contractTiles.forEach(tile => tile.hasChanges = false);
        this.changesOnTiles = false;
    }

    private checkEachTileHasChosenInstruction(): void {
        let allSet = this.contractTiles.length > 0;
        this.contractTiles.forEach(tile => {
            const currentFormControl = tile.formGroup.get('instructionId');
            if (tile.externalContractData.validationResult.instructions.length > 0 && currentFormControl.value <= 0) {
                allSet = false;
            }
        });
        this.eachTileHasChosenInstruction = allSet;
    }

    private checkEachTileHasChosenTariff(): void {
        let allSet = this.contractTiles.length > 0;
        this.contractTiles.forEach(tile => {
            if (tile.formGroup.invalid) allSet = false;
        });
        this.eachTileHasChosenTariff = allSet;
    }

    private openProvisionChangeOverlay(): MatDialogRef<ProvisionChangeOverlayComponent> {
        const originalProvisionRecipientIds = [];
        this.contractTiles.forEach(tile => {
            if (tile.getSelectedInstruction() !== undefined && tile.getSelectedInstruction().id === INSTRUCTION_TRANSFER_TO_QUALITYPOOL) {
                const provisionRecipient = tile.externalContractData.originalProvisionRecipientId;
                if (!originalProvisionRecipientIds.includes(provisionRecipient)) {
                    originalProvisionRecipientIds.push(provisionRecipient);
                }
            }
        });

        const dialogRef = this.dialog.open(ProvisionChangeOverlayComponent,
            {
                width: '660px',
                data: {
                    personManagementId: this.personManagementId,
                    originalProvisionRecipients: originalProvisionRecipientIds
                },
                autoFocus: false
            }
        );
        dialogRef.disableClose = true;
        return dialogRef;
    }

    private openBrokerContractOverlay(): void {
        const contractTilesWithoutBrokerContract = this.getContractTilesWithoutBrokerContract();
        if (contractTilesWithoutBrokerContract.length > 0) {
            const dialogRef = this.dialog.open(BrokerContractOverlayComponent,
                {
                    width: '660px',
                    data: {
                        personManagementId: this.personManagementId,
                        customerId: this.customer.id,
                        contractTiles: contractTilesWithoutBrokerContract
                    },
                    autoFocus: false
                }
            );
            dialogRef.disableClose = true;
        } else {
            this.redirectToKonzept();
        }
    }

    redirectToKonzept(): void {
        this.document.location.href = `${this.externalContractsService.getKonzeptUrl(this.personManagementId)}`;
    }

    private getContractTilesWithoutBrokerContract() {
        return this.contractTiles.filter(tile => {
            const brokerContractExistence = this.externalContractsService.getBrokerContractExistences()
                .find(existence => existence.divisionId === tile.externalContractData.division.id);
            // show only the ones that have no broker contract and should be transferred
            return (brokerContractExistence === undefined || brokerContractExistence.exists === false) &&
                tile.getSelectedInstruction() !== undefined && tile.getSelectedInstruction().id === INSTRUCTION_TRANSFER_TO_QUALITYPOOL;
        });
    }
}
