<div class="flex align-items-center">
  <h2 mat-dialog-title class="flex-grow-1 mb-0">Achtung</h2>
  <button class="mr-2" mat-icon-button mat-dialog-close [autofocus]="false">
    <mat-icon class="close-icon">close</mat-icon>
  </button>
</div>
<mat-dialog-content>
  Möchtest du wirklich fortfahren? <br/> Alle nicht gespeicherten Änderungen gehen verloren.
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-stroked-button (click)="close()">Abbrechen</button>
  <button mat-flat-button class="bg-error text-white" (click)="closeOrRedirectToKonzept()">Fortfahren</button>
</mat-dialog-actions>
