<h2 mat-dialog-title class="mb-0">Zusammenfassung</h2>
<mat-dialog-content>
  <p class="font-normal">Folgende Verträge sollen in den Bestand bzw. als Korrespondenzmakler übernommen werden:</p>
  <div class="contracts">
    <ul class="list-disc">
      <li *ngFor="let contract of contractsToTransfer">
        {{contract.contractTypeName}}, {{contract.insurerName}}, {{contract.number}}
      </li>
      <li *ngIf="!contractsToTransfer.length">
        Keine Verträge zur Übertragung ausgewählt
      </li>
    </ul>
  </div>
  <p class="font-normal" *ngIf="contractsNotToTransfer.length">Folgende Verträge sollen <b>nicht</b> in den Bestand übernommen werden:
  </p>
  <div class="contracts" *ngIf="contractsNotToTransfer.length">
    <ul class="list-disc">
      <li *ngFor="let contract of contractsNotToTransfer">
        {{contract.contractTypeName}}, {{contract.insurerName}}, {{contract.number}}
      </li>
    </ul>
  </div>
  <div class="mb-4">
    Die <b>Dokumentation</b> des Kundenwunsches wird automatisch erstellt und an folgende E-Mail-Adresse<span
    *ngIf="data.customerMailAddress">n</span> versendet:<br/><br/>
    <span *ngIf="data.customerMailAddress">{{data.customerMailAddress}}<br/></span>
    <span *ngIf="data.consultantMailAddress">{{data.consultantMailAddress}}</span>
  </div>
  <div class="red-text mb-4">
    <h3 class="mb-1 text-base line-height-3">Bitte bestätigen:</h3>
    <mat-checkbox color="primary" class="top-aligned-checkbox overlay-checkbox" [(ngModel)]="checkboxContentValidated">Der Umfang der zu übertragenden Verträge wurde geprüft
      und mit dem Kundenbedarf abgeglichen. Im Falle einer Deckungslücke wurde der Kunde über die M&ouml;glichkeiten
      einer Aufstockung seines Vertrags oder den Abschluss einer Differenzdeckung informiert.
    </mat-checkbox>
    <mat-checkbox color="primary" class="top-aligned-checkbox mt-2 overlay-checkbox" *ngIf="!data.customerMailAddress" [(ngModel)]="checkboxDocumentationWithoutMail">Für den Kunden ist keine E-Mail-Adresse
      hinterlegt, oder er hat deren Nutzung nicht zugestimmt. Ich bestätige, dass ich dem Kunden die Dokumentation
      in anderer geeigneter Form (z. B. in Papierform oder auf Datenträger) aushändige.
    </mat-checkbox>
  </div>
  <div class="mb-3 p-2 px-075 highlight-box highlight-text" *ngIf="contractsToTransfer.length && !data.hasBrokerMandate">
    <div class="flex mb-2 line-height-3 align-items-center">
      <div class="flex-shrink-0">
        <mat-icon class="block box-icon-scale" fontSet="material-icons-outlined">report_problem</mat-icon>
      </div>
      <div class="ml-1 font-medium">Wichtig:</div>
    </div>
    <div class="overlay-box-text">Die Bestandsübertragung wird erst nach Einreichung und Prüfung
        der aktuellen Maklervollmacht (innerhalb von max. 30 Tagen) versendet!</div>
  </div>
  <mat-dialog-actions class="p-0" align="end">
    <button mat-stroked-button color="link" (click)="close()">Bestandsübertragung abbrechen</button>
    <button mat-flat-button mat-dialog-close color="primary" (click)="startTransfer()"
            [disabled]="disableTransfer || (!contractsToTransfer.length && !contractsNotToTransfer.length) ||
          (!data.customerMailAddress && !checkboxDocumentationWithoutMail) ||
          !checkboxContentValidated">
      <span *ngIf="contractsToTransfer.length">{{ fetchSubmitButtonText() }}</span>
      <span *ngIf="!contractsToTransfer.length && contractsNotToTransfer">Kundenwunsch dokumentieren</span>
    </button>
  </mat-dialog-actions>
</mat-dialog-content>
