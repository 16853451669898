import { Component, Inject, LOCALE_ID, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ExternalContractsService } from '../../services/external-contracts.service';
import { ObservableDataSource } from '@taures/angular-commons';
import { Insurer, TransferType } from '../../shared-objects';
import { switchMap, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog'
import { InsurerChangeOverlayComponent } from '../insurer-change-overlay/insurer-change-overlay.component'

@Component({
  selector: 'tr-insurers-list',
  templateUrl: './insurers-list.component.html',
  styleUrls: ['./insurers-list.component.scss'],
  host: { class: 'flex flex-column overflow-y-auto h-full' }
})
export class InsurersListComponent implements OnInit, OnDestroy {
  dataSource: ObservableDataSource<Insurer>;
  displayedColumns: string[] = ['name', 'hidden', 'transferType', 'correspondenceOnly', 'email', 'actions'];

  filterControl = new UntypedFormControl();
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  private destroyed = new Subject();

  constructor(public externalContractsService: ExternalContractsService, @Inject(LOCALE_ID) private locale: string, public dialog: MatDialog) {
    this.dataSource = new ObservableDataSource<Insurer>(this.externalContractsService.loadInsurerList(), locale);
  }

  ngOnInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.filterControl.valueChanges
      .pipe(takeUntil(this.destroyed))
      .subscribe(value => this.dataSource.filter = value);
    this.externalContractsService.loadDivisionsList().subscribe()
  }

  ngOnDestroy() {
    this.destroyed.next(null);
    this.destroyed.complete();
  }

  mapTransferType (transferType: TransferType) {
    switch (transferType) {
      case TransferType.QUALITYPOOL:
        return 'Qualitypool';
      case TransferType.DIRECT:
        return 'direkt';
      case TransferType.NONE:
        return '---';
    }
  }

  editInsurer (insurer: Insurer) {
    this.dialog.open(InsurerChangeOverlayComponent,
      {
        width: '660px',
        data: { insurer }
      }
    ).afterClosed()
      .pipe(switchMap(() => this.externalContractsService.loadInsurerList()))
      .subscribe((insurers) => this.dataSource.data = insurers)
  }
}
