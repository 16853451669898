<div class="flex items-center">
  <h1 mat-dialog-title class="flex-grow pb-0">Gesellschaft: {{data.insurer.name}}</h1>
  <button class="mr-2" mat-icon-button mat-dialog-close [autofocus]="false">
    <mat-icon class="close-icon">close</mat-icon>
  </button>
</div>
<div mat-dialog-content class="pt-0">
  <form [formGroup]="form" (submit)="onSubmit()" novalidate class="flex flex-column">
    <mat-checkbox color="primary" formControlName="hidden" class="mb-3">Gesellschaft in Echse ausblenden</mat-checkbox>

    <div class="mb-3">
      <div class="mb-2">Einreichungsweg</div>
      <mat-radio-group class="blue-radio-label" formControlName="transferType" required>
        <mat-radio-button *ngFor="let transferType of transferTypes" [value]="transferType.value" class="block">{{transferType.title}}</mat-radio-button>
      </mat-radio-group>
    </div>

    <mat-checkbox color="primary" formControlName="correspondenceOnly" class="mb-3" *ngIf="form.get('transferType').value !== 'NONE'">Nur Korrespondenz</mat-checkbox>

    <div *ngIf="form.get('transferType').value === 'DIRECT'">
      <mat-form-field class="block" title="E-Mail">
        <mat-label>E-Mail</mat-label>
        <input matInput maxlength="64" formControlName="email">
      </mat-form-field>

      <mat-form-field class="block" title="E-Mail Cc">
        <mat-label>E-Mail Cc</mat-label>
        <input matInput maxlength="64" formControlName="emailCC">
      </mat-form-field>

      <mat-form-field class="block" title="Telefonnummer">
        <mat-label>Vermittlernummer TauRes</mat-label>
        <input matInput maxlength="64" formControlName="brokerNumber">
      </mat-form-field>
    </div>

    <tr-autocomplete
            *ngIf="form.get('transferType').value === 'DIRECT'"
            [options]="divisions | async"
            formControlName="divisionIds"
            label="Sparten"
            placeholder="Sparte..."></tr-autocomplete>
  </form>
</div>
<div mat-dialog-actions align="end" class="flex gap-[5px] mr-3 mb-3">
  <button mat-raised-button type="button" mat-dialog-close [disabled]="loading">Abbrechen</button>
  <button mat-raised-button type="submit" color="primary" [disabled]="loading || !form.valid" (click)="onSubmit()">
    Speichern
  </button>
</div>
