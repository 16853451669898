import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

export interface NotificationDialogData {
  message: string,
  error?: boolean,
  details?: {insurer: string, contractNumber: string}[]
}

@Component({
  selector: 'tr-notification-dialog',
  templateUrl: './notification-dialog.component.html',
  styleUrls: ['./notification-dialog.component.scss']
})
export class NotificationDialogComponent {
  color: string
  constructor(@Inject(MAT_DIALOG_DATA) public data: NotificationDialogData) {
    this.color = data.error ? '#DD2C00' : '#C2185B'
  }
}
