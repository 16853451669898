export const environment = {
  production: true,
  oauth: {
    issuer: 'ISSUER_URL',
    clientId: 'CLIENT_ID',
    scope: 'openid email',
    responseType: 'code',
    disablePKCE: true // this is not recommended, but otherwise, the keycloak.js adapter would not work
  },
  crmBaseUrl: '/t-it-s',
  echse: '/echse-frontend',
  konzeptUrl: 'KONZEPT_URL',
  docsUrl: 'DOCS_URL',
  imports: []
};
