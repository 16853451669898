import {ChangeDetectionStrategy, Component, EventEmitter, Inject, Output, ViewEncapsulation} from '@angular/core';
import {ExternalContractsService} from '../../services/external-contracts.service';
import {DOCUMENT} from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface DialogData {
  personManagementId: string;
  visitedFromCrm: boolean;
}

@Component({
  selector: 'tr-canceloverlay',
  templateUrl: './cancel-operation-overlay.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class CancelOperationOverlayComponent {
  @Output() discardChanges = new EventEmitter<any>(true);

  constructor(@Inject(DOCUMENT) private document: Document,
              @Inject(MAT_DIALOG_DATA) private data: DialogData,
              private dialogRef: MatDialogRef<CancelOperationOverlayComponent>,
              private externalContractsService: ExternalContractsService) {
  }

  closeOrRedirectToKonzept(): void {
    if (this.data.visitedFromCrm) {
      this.discardChanges.emit();
    } else {
      this.document.location.href = this.externalContractsService.getKonzeptUrl(this.data.personManagementId);
    }
  }

  close(): void {
    this.dialogRef.close();
  }
}
