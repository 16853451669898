<div class="flex flex-grow-1 flex-column shadow-2" [formGroup]="formGroup">
  <div class="detail-card-header px-3 py-075 taures-blue-1 white-space-nowrap line-height-3 overflow-x-hidden">{{ getDevisionLabel() }} ({{ getInsurerName() }})</div>
  <div class="px-3 py-125">
    <div class="flex">
      <mat-form-field class="block flex-grow-1">
        <mat-label>Sparte</mat-label>
        <mat-select formControlName="divisionId" id="divisionId" required
                    (selectionChange)="divisionChangeAction($event.value)"
                    #singleSelectDivision>
          <mat-option>
            <ngx-mat-select-search [formControl]="divisionsFilterCtrl"></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let division of filteredDivisions | async;" [value]="division.id">
            {{ division.label }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <div class="pl-2 flex align-items-center pb-2">
        <mat-chip-set>
          <mat-chip-option *ngIf="brokerContractExists" disabled class="chip-height taures-blue-2 opacity-100 text-xs">Mit Maklervertrag</mat-chip-option>
          <mat-chip-option *ngIf="!brokerContractExists" disabled class="chip-height taures-highlight opacity-100 text-xs">Ohne Maklervertrag</mat-chip-option>
        </mat-chip-set>
      </div>
    </div>

    <mat-form-field class="block">
      <mat-label>Versicherer</mat-label>
      <mat-select formControlName="insurerId" id="insurerId" required
                  (selectionChange)="insurerChangeAction($event.value)"
                  #singleSelectInsurer>
        <mat-option>
          <ngx-mat-select-search [formControl]="insurersFilterCtrl"></ngx-mat-select-search>
        </mat-option>
        <mat-option *ngFor="let insurer of filteredInsurers | async;" [value]="insurer.id">
          {{ insurer.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="block">
      <mat-label>Tarifbezeichnung</mat-label>
      <mat-select formControlName="tariffId"
                  id="tariffId"
                  (selectionChange)="tariffChangeAction($event.value)"
                  required
      >
        <mat-option *ngFor="let tariff of tariffs;" [value]="tariff.id">
          {{ tariff.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="block" *ngIf="selectedTariffIsUnknown">
      <mat-label>Neue Tarifbezeichnung</mat-label>
      <input matInput formControlName="unknownTariffName">
    </mat-form-field>

    <mat-form-field class="block">
      <mat-label>Versicherungsnummer</mat-label>
      <input matInput (change)="contractNumberChangeAction(formGroup.get('contractNumber').value)"
             formControlName="contractNumber"
             maxlength="30"
             required>
    </mat-form-field>

    <div class="mb-3 p-2 px-075" *ngIf="externalContractData.validationResult.reasons.length > 0" [ngClass]="externalContractData.validationResult.error ? 'highlight-box highlight-text' : 'info-box text-black-alpha-60'">
      <div class="flex mb-2 line-height-3 align-items-center">
        <div class="flex-shrink-0">
          <mat-icon class="block box-icon-scale" fontSet="material-icons-outlined">{{ externalContractData.validationResult.error ? 'report_problem' : 'info_outline' }}</mat-icon>
        </div>
        <div class="ml-1 font-medium">{{ externalContractData.validationResult.error ? 'Hinweis:' : 'Information:' }}</div>
      </div>
      <div>
        <div *ngIf="externalContractData.validationResult.reasons.length === 1">{{ externalContractData.validationResult.reasons[0] }}</div>
        <ul class="list-disc ml-125 mt-0 mb-0 p-0" *ngIf="externalContractData.validationResult.reasons.length > 1">
          <li *ngFor="let reason of externalContractData.validationResult.reasons" class="py-1">{{ reason }}</li>
        </ul>
      </div>
    </div>

    <div>
      <label id="instruction-id" class="block font-base option-label" [ngClass]="{'invalid-label': formGroup.get('instructionId').invalid}">Optionen *</label>
      <mat-radio-group class="blue-radio-label" formControlName="instructionId" *ngIf="externalContractData.validationResult.instructions" aria-labelledby="instruction-id">
        <mat-radio-button *ngFor="let instruction of instructions" color="primary"
                          [value]="instruction.id" [disabled]="isInstructionDisabled(instruction.id)" class="block radio-medium-label">
          {{ instruction.label }}
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <div class="flex-grow-1"></div>
  <div class="flex px-3 pb-3" *ngIf="hasChanges">
    <div class="flex-grow-1"></div>
    <button mat-stroked-button color="primary" (click)="resetTileAction()">Änderungen verwerfen</button>
  </div>
</div>
