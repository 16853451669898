import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {ErrorMessageOverlayComponent} from '../error-message-overlay/error-message-overlay.component';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'tr-queue-warning-overlay',
  templateUrl: './queue-warning-overlay.component.html',
  styleUrls: ['./queue-warning-overlay.component.scss']
})
export class QueueWarningOverlayComponent {

  constructor(private dialogRef: MatDialogRef<ErrorMessageOverlayComponent>) { }
  @Output() transmit = new EventEmitter<any>(true);

  close(): void {
    this.transmit.emit();
    this.dialogRef.close();
  }

}
