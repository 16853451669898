<div class="flex align-items-center gap-2 py-4 px-3">
    <mat-icon *ngIf="!data.error" class="notification-dialog-icon" fontSet="material-icons-outlined">report_problem</mat-icon>
    <mat-icon *ngIf="data.error" class="error-dialog-icon" fontSet="material-icons-outlined">error_outline</mat-icon>
    <h2 mat-dialog-title class="p-0 mb-0">{{data.error ? 'Fehler' : 'Hinweis'}}</h2>
</div>
<mat-dialog-content>
  <span>{{data.message}}</span>
  <ul class="my-2 list-disc" *ngIf="data.details && data.details.length > 0">
    <li *ngFor="let contract of data.details" class="pb-1">{{contract.insurer}} - {{contract.contractNumber}}</li>
  </ul>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-flat-button class="notification-dialog-button" [style.background-color]="color" mat-dialog-close>Ok</button>
</mat-dialog-actions>
