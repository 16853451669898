import {Component, EventEmitter, Inject, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {PersonsService} from '../../services/persons.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import {Subject} from 'rxjs';
import {Consultant} from '../../shared-objects';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ExternalContractsService} from '../../services/external-contracts.service';

export interface DialogData {
  personManagementId: string;
  originalProvisionRecipients: string[];
}

@Component({
  selector: 'tr-provision-change-overlay',
  templateUrl: './provision-change-overlay.component.html',
  styleUrls: ['./provision-change-overlay.component.scss']
})
export class ProvisionChangeOverlayComponent implements OnInit, OnDestroy {

  @ViewChild('singleSelectConsultant', {static: true}) singleSelectConsultant: MatSelect;

  @Output() transmit = new EventEmitter<any>(true);

  _onDestroy = new Subject<void>();
  formGroup: UntypedFormGroup;
  consultantsSearchCtrl: UntypedFormControl = new UntypedFormControl();
  provisionRecipientNames = '';

  constructor(@Inject(MAT_DIALOG_DATA) readonly data: DialogData,
              public personsService: PersonsService,
              private externalContractsService: ExternalContractsService,
              private formBuilder: UntypedFormBuilder) {
  }

  ngOnInit() {
    this.createFormControls();
    this.personsService.loadConsultantsInLowerHierarchy('').subscribe();
    // fetch names of original provision recipients by fetching their customer-data
    this.data.originalProvisionRecipients.forEach(provisionRecipientId => {
      if (provisionRecipientId && provisionRecipientId.length > 0) {
        this.personsService.loadCustomer(provisionRecipientId).subscribe(customer => {
          if (this.provisionRecipientNames.length > 0) {
            this.provisionRecipientNames += ', ';
          }
          this.provisionRecipientNames += `${customer.firstName} ${customer.lastName}`;
        });
      }
    });
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  createFormControls(): void {
    this.formGroup = this.formBuilder.group({
      // always select first entry
      'consultantId': [0, Validators.required]
    });
  }

  getSelectedConsultant(): Consultant {
    if (this.formGroup.get('consultantId').value > 0) {
      return this.personsService.getConsultantsInLowerHierarchy().find(x => x.id === this.formGroup.get('consultantId').value);
    }
  }

  submit(): void {
    const consultant = this.getSelectedConsultant();
    if (this.formGroup.get('consultantId').value > 0) {
      this.externalContractsService.changeProvisionRecipient(this.data.personManagementId, consultant.id).subscribe();
    }
    this.transmit.emit();
  }

  fetchConsultantsByPartialName() {
    this.personsService.loadConsultantsInLowerHierarchy(this.consultantsSearchCtrl.value).subscribe(() =>
      console.error(this.personsService.getConsultantsInLowerHierarchy().length)
    );
  }
}
