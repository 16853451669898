import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Inject,
    OnInit,
    Output,
    QueryList,
    ViewEncapsulation
} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {DetailComponent} from '../external-contracts-detail/detail.component';
import {ExternalContractsService} from '../../services/external-contracts.service';
import {ExternalContractData, INSTRUCTION_NO_TRANSFER, INSTRUCTION_TRANSFER_TO_QUALITYPOOL} from '../../shared-objects';
import {catchError, switchMap} from 'rxjs/operators';
import {handleProcessError} from '../external-contracts-list/list.component'
import {SnackbarService} from "@taures/angular-commons";

export interface DialogData {
    personManagementId: string;
    contractTiles: QueryList<DetailComponent>;
    customerMailAddress: string;
    consultantMailAddress: string;
    hasBrokerMandate: boolean;
}

export class ContractData {
    number: string;
    insurerName: string;
    contractTypeName: string;
}

export const PROCESS_ERRORS = {
    'documentation': 'Bei der Dokumentation ist ein Fehler aufgetreten. Bitte starte den Vermittlerwechsel zu einem späteren Zeitpunkt noch einmal.',
    'noAddress': 'Keine gültige Hauptanschrift erfasst. Eine Bestandsübertragung ist nicht möglich.',
    'invalidAddress': 'Fehlerhafte Meldeadresse. Eine Bestandsübertragung ist nicht möglich.',
    'invalidBundle': 'Uneinheitliche Entscheidung für Bündelvertragssparten bitte korrigieren:',
    'unknown': 'Die Aktionen können derzeit nicht durchgeführt werden, bitte versuchen Sie es später noch einmal.',
}

export enum DIALOG_STATE {
    'LOADING',
    'FINISHED',
    'COMPLETED'
}

@Component({
    selector: 'tr-pre-check-overlay',
    templateUrl: './pre-check-overlay.component.html',
    styleUrls: ['./pre-check-overlay.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class PreCheckOverlayComponent implements OnInit {

    @Output() transmit = new EventEmitter<any>(true);
    contractsToTransfer: ContractData[] = [];
    contractsNotToTransfer: ContractData[] = [];
    contractsAll: ExternalContractData[] = [];
    checkboxContentValidated = false;
    checkboxDocumentationWithoutMail = false;
    disableTransfer = false;

    constructor(@Inject(DOCUMENT) private document: Document,
                @Inject(MAT_DIALOG_DATA) readonly data: DialogData,
                private dialog: MatDialog,
                private externalContractsService: ExternalContractsService,
                private dialogRef: MatDialogRef<PreCheckOverlayComponent>,
                private snackBarService: SnackbarService) {
    }

    ngOnInit(): void {
        this.fetchContracts();
    }

    close(): void {
        this.dialogRef.close();
    }

    startTransfer(): void {
        try {
            this.transmit.emit(DIALOG_STATE.LOADING)
            this.disableTransfer = true
            this.externalContractsService.saveInitiator(this.contractsAll).pipe(
                switchMap(() => {
                    return this.externalContractsService.processContracts(this.data.personManagementId)
                        .pipe(catchError(err => {
                            this.transmit.emit(DIALOG_STATE.FINISHED);
                            return handleProcessError(this.dialog, err)
                        }))
                })
            ).subscribe(result => {
                if (this.data.hasBrokerMandate) {
                    this.snackBarService.queueToastMessage({
                        title: 'Bestätigung',
                        message: 'Die gewählten Aktionen wurden ausgeführt und die Dokumentation per E-Mail versendet',
                        duration: 6000,
                        notificationType: 'confirmation'
                    })
                }
                this.transmit.emit(DIALOG_STATE.COMPLETED);
            });
        } catch (err) {
            this.transmit.emit(DIALOG_STATE.FINISHED);
            this.snackBarService.queueToastMessage({
                title: 'Fehler',
                message: err.message,
                duration: 6000,
                notificationType: 'error'
            })
        }
    }

    fetchContracts(): void {
        this.data.contractTiles.forEach(contractTile => {
            this.contractsAll.push(contractTile.externalContractData);

            if ((contractTile.formGroup.get('instructionId').value === INSTRUCTION_TRANSFER_TO_QUALITYPOOL) &&
                contractTile.externalContractData.validationResult.instructions.length > 0) {
                const insurer = contractTile.getSelectedInsurer();
                const contractType = contractTile.getSelectedDivision();
                this.contractsToTransfer.push({
                    number: contractTile.formGroup.get('contractNumber').value,
                    insurerName: insurer ? insurer.name : 'Versicherer fehlt',
                    contractTypeName: contractType ? contractType.label : 'Sparte fehlt'
                });
            } else if (contractTile.formGroup.get('instructionId').value === INSTRUCTION_NO_TRANSFER &&
                contractTile.externalContractData.validationResult.instructions.length > 0) {
                const insurer = contractTile.getSelectedInsurer();
                const contractType = contractTile.getSelectedDivision();
                this.contractsNotToTransfer.push({
                    number: contractTile.formGroup.get('contractNumber').value,
                    insurerName: insurer ? insurer.name : 'Versicherer fehlt',
                    contractTypeName: contractType ? contractType.label : 'Sparte fehlt'
                });
            }
        });
    }

    fetchSubmitButtonText() {
        if (this.data.hasBrokerMandate) {
            return 'Dokumentieren und Verträge übernehmen';
        } else {
            return 'Vertragsübernahme vorläufig starten';
        }
    }
}
