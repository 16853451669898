import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ListComponent } from './components/external-contracts-list/list.component';
import { ExternalContractsService } from './services/external-contracts.service';
import { HttpClientModule } from '@angular/common/http';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { DetailComponent } from './components/external-contracts-detail/detail.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '../environments/environment';
import { MomentDateModule } from '@angular/material-moment-adapter';
import { OverlayModule } from '@angular/cdk/overlay';
import { BrokerMandateOverlayComponent } from './components/broker-mandate-overlay/broker-mandate-overlay.component';
import { CancelOperationOverlayComponent } from './components/cancel-operation-overlay/cancel-operation-overlay.component';
import { PreCheckOverlayComponent } from './components/pre-check-contract-transmission-overlay/pre-check-overlay.component';
import { ErrorMessageOverlayComponent } from './components/error-message-overlay/error-message-overlay.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { PersonsService } from './services/persons.service';
import { BrokerContractOverlayComponent } from './components/broker-contract-overlay/broker-contract-overlay.component';
import { ProvisionChangeOverlayComponent } from './components/provision-change-overlay/provision-change-overlay.component';
import { QueueWarningOverlayComponent } from './components/queue-warning-overlay/queue-warning-overlay.component';
import { AppInitService, AppInitServiceFactory } from './services/app-init.service';
import { OauthLogoutService } from './services/oauth-logout.service';
import { AngularCommonsModule, LogoutService, TauresMenueModule } from '@taures/angular-commons';
import { OAuthModule } from 'angular-oauth2-oidc';
import { RedirectKonzeptComponent } from './components/redirect-konzept/redirect-konzept.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { NotificationDialogComponent } from "./components/notification-dialog/notification-dialog.component";
import { InsurersListComponent } from './components/insurers-list/insurers-list.component';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { GermanPaginatorIntl } from './services/german-paginator-intl';
import { InsurerChangeOverlayComponent } from './components/insurer-change-overlay/insurer-change-overlay.component'
import { MatChipsModule } from '@angular/material/chips';
import { AutocompleteComponent } from './components/autocomplete/autocomplete.component'
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { KonzeptHeaderComponent } from './components/konzept-header/konzept-header.component'
import { KonzeptFooterComponent } from './components/konzept-footer/konzept-footer.component'
import { KonzeptSubheaderComponent } from './components/konzept-subheader/konzept-subheader.component'

@NgModule({
    declarations: [
        AppComponent,
        ListComponent,
        DetailComponent,
        BrokerMandateOverlayComponent,
        CancelOperationOverlayComponent,
        PreCheckOverlayComponent,
        ErrorMessageOverlayComponent,
        BrokerContractOverlayComponent,
        ProvisionChangeOverlayComponent,
        QueueWarningOverlayComponent,
        RedirectKonzeptComponent,
        PageNotFoundComponent,
        NotificationDialogComponent,
        InsurerChangeOverlayComponent,
        InsurersListComponent,
        AutocompleteComponent,
        KonzeptHeaderComponent,
        KonzeptFooterComponent,
        KonzeptSubheaderComponent
    ],
    imports: [
        AppRoutingModule,
        BrowserAnimationsModule,
        BrowserModule,
        AngularCommonsModule,
        TauresMenueModule,
        environment.imports,
        OAuthModule.forRoot({resourceServer: {sendAccessToken: true}}),
        FormsModule,
        HttpClientModule,
        MatButtonModule,
        MatCardModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatDialogModule,
        MatFormFieldModule,
        MatChipsModule,
        MatAutocompleteModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatSelectModule,
        MatSnackBarModule,
        MatSortModule,
        MatTableModule,
        MatToolbarModule,
        MomentDateModule,
        ReactiveFormsModule,
        OverlayModule,
        NgxMatSelectSearchModule,
        MatPaginatorModule
    ],
    providers: [
        AppInitService,
        {
            provide: LogoutService,
            useClass: OauthLogoutService
        },
        {
            provide: APP_INITIALIZER,
            useFactory: AppInitServiceFactory,
            multi: true,
            deps: [AppInitService]
        },
        {
            provide: MatPaginatorIntl,
            useClass: GermanPaginatorIntl
        },
        ExternalContractsService,
        PersonsService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
