import { Component } from '@angular/core';
import { LogoutService } from '@taures/angular-commons';

@Component({
  selector: 'tr-konzept-header',
  templateUrl: './konzept-header.component.html',
  styleUrls: ['./konzept-header.component.scss'],
  host: { class: 'flex align-items-center text-white z-2' }
})
export class KonzeptHeaderComponent {
  constructor(readonly logoutService: LogoutService) { }
}
