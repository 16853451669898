<h2 mat-dialog-title class="mb-0">Möchtest du den <b>Provisionsempfänger</b> für die zu übertragenden Verträge ändern?</h2>
<mat-dialog-content class="flex flex-column pb-0" [formGroup]="formGroup">
  <mat-form-field>
    <mat-select formControlName="consultantId"
                placeholder="Bitte wählen"
                id="consultantId"
                required
                #singleSelectConsultant>
      <mat-option>
        <ngx-mat-select-search [formControl]="consultantsSearchCtrl" (keyup)="fetchConsultantsByPartialName()"></ngx-mat-select-search>
      </mat-option>
      <mat-option [value]="0">
        Keine Änderung <span *ngIf="provisionRecipientNames.length > 0">({{ provisionRecipientNames }})</span>
      </mat-option>
      <mat-option *ngFor="let consultant of personsService.getConsultantsInLowerHierarchy();" [value]="consultant.id">
        {{ consultant.lastName }}, {{ consultant.firstName }} ({{consultant.birthDate | date: 'dd.MM.yyyy'}})
      </mat-option>
    </mat-select>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button color="primary" (click)="submit()" class="margin-right">OK</button>
</mat-dialog-actions>
