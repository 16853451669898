import {ChangeDetectionStrategy, Component, Inject, ViewEncapsulation} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface DialogData {
  message: string;
  closeButtonText?: string
}

@Component({
  selector: 'tr-errormessageoverlay',
  templateUrl: './error-message-overlay.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ErrorMessageOverlayComponent {
  constructor(@Inject(MAT_DIALOG_DATA) readonly data: DialogData,
              private dialogRef: MatDialogRef<ErrorMessageOverlayComponent>) {
  }

  close(): void {
    this.dialogRef.close();
  }
}
