import { NgModule} from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ListComponent } from './components/external-contracts-list/list.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { RedirectKonzeptComponent } from './components/redirect-konzept/redirect-konzept.component';
import { InsurersListComponent } from './components/insurers-list/insurers-list.component';

export const appRoutes: Routes = [
  { path: '', pathMatch: 'full', component: RedirectKonzeptComponent },
  { path: 'customers/:personManagementId/:crmContractId', component: ListComponent },
  { path: 'customers/:personManagementId', component: ListComponent },
  { path: 'admin', component: InsurersListComponent, data: { title: 'Echse-Administration' } },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
