<tr-konzept-subheader class="z-1" [customer]="customer"></tr-konzept-subheader>
<main class="flex-1 overflow-y-auto pt-3 pb-8">
  <div class="list-width mx-auto">
    <div
      *ngIf="externalContractsService.getDivisions()
             && externalContractsService.getInsurers()
             && externalContractsService.getContracts()
             && externalContractsService.getBrokerContractExistences()" class="grid px-2 m-0">
        <div *ngFor="let externalContract of externalContractsService.getContracts(); index as i" class="col-12 lg:col-6 xl:col-4 pt-0 pb-3">
            <tr-external-contract-detail class="h-full"
                                         [divisions]="externalContractsService.getDivisions()"
                                         [insurers]="externalContractsService.getInsurers()"
                                         [externalContractData]="externalContract"
                                         [personManagementId]="personManagementId">
            </tr-external-contract-detail>
        </div>
    </div>
    <div *ngIf="showSpinner || !(externalContractsService.getDivisions()
             && externalContractsService.getInsurers()
             && externalContractsService.getContracts()
             && externalContractsService.getBrokerContractExistences())">
      <div class="absolute top-0 left-0 right-0 bottom-0 bg-white-alpha-50">
        <div class="absolute top-50 left-50 -translate-x-50 -translate-y-50">
          <mat-progress-spinner mode="indeterminate" [diameter]="48" [strokeWidth]="4"></mat-progress-spinner>
        </div>
      </div>
    </div>
  </div>
</main>

<div class="control-pane"
     *ngIf="externalContractsService.getDivisions() && externalContractsService.getInsurers() && externalContractsService.getContracts() && externalContractsService.getBrokerContractExistences()">
  <div class="centered">
    <button mat-raised-button color="link" (click)="cancel()" [disabled]="showSpinner" class="margin-right">Schließen</button>
    <button mat-raised-button color="link" class="margin-right" [disabled]="!savingActivated" (click)="save()">
      Speichern
    </button>
    <button mat-raised-button color="primary" [disabled]="!processingActivated" (click)="process()">Weiter</button>
  </div>
</div>
