<mat-form-field class="block" *ngIf="options">
    <mat-label>{{ label }}</mat-label>
    <mat-chip-grid #chipList aria-label="Selection">
        <mat-chip-row *ngFor="let item of value" (removed)="remove(item)">
            {{ toLabel(item) }}
            <button matChipRemove>
                <mat-icon>cancel</mat-icon>
            </button>
        </mat-chip-row>
    </mat-chip-grid>
    <input  #searchInput
            [placeholder]="placeholder"
            [formControl]="searchControl"
            [matAutocomplete]="auto"
            [matChipInputFor]="chipList">
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event);">
        <mat-option *ngFor="let item of filteredOptions | async" [value]="item">
            <div (click)="select($event, item)">{{item.label}}</div>
        </mat-option>
    </mat-autocomplete>
</mat-form-field>
