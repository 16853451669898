import {ChangeDetectionStrategy, Component, Inject, QueryList, ViewEncapsulation} from '@angular/core';
import {ExternalContractsService} from '../../services/external-contracts.service';
import {DOCUMENT} from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface BrokerMandateOverlayData {
  customerId: number;
}

@Component({
  selector: 'tr-bmo',
  templateUrl: './broker-mandate-overlay.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class BrokerMandateOverlayComponent {
  constructor(@Inject(DOCUMENT) private document: Document,
              @Inject(MAT_DIALOG_DATA) private data: BrokerMandateOverlayData,
              private dialogRef: MatDialogRef<BrokerMandateOverlayComponent>,
              private externalContractsService: ExternalContractsService) {
  }

  redirectToKonzept(): void {
    this.document.location.href = `${this.externalContractsService.getDocsUrl(this.data.customerId)}/proxy`;
  }

  close(): void {
    this.dialogRef.close();
  }
}
