<h2 mat-dialog-title class="mb-0">Hinweis:</h2>
<mat-dialog-content>
  <div>Folgende Verträge sind derzeit noch nicht vom Maklervertrag umfasst:</div>
  <div class="contracts">
    <ul class="list-disc">
      <li *ngFor="let contract of data.contractTiles">
        {{contract.externalContractData.division.label}},
        {{contract.externalContractData.insurer.name}},
        {{contract.externalContractData.contractNumber}}
      </li>
    </ul>
  </div>
  <div>Zur Vermeidung einer <b>Nachbearbeitung</b> muss der erweiterte und unterschriebene Maklervertrag <b>innerhalb von 3
    Tagen</b> eingereicht werden!</div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-stroked-button color="link" (click)="redirectToKonzept()">Verstanden, ich reiche den erweiterten MV nach
  </button>
  <button mat-flat-button color="primary" (click)="redirectToBrokerContractGenerator()">Fehlende Sparten jetzt
    ergänzen
  </button>
</mat-dialog-actions>
